import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-sidebarmenu',
  templateUrl: './sidebarmenu.component.html',
  styleUrls: ['./sidebarmenu.component.scss'],
})
export class SidebarmenuComponent implements OnInit {

  openDetails = false;
  benefit = false;
  constructor(
    private menu: MenuController,
  ) { }

  ngOnInit() { }

  openDropdown(ev) {
    switch (ev) {
      case 'benefit':
        this.benefit = !this.benefit;
        break;
      default:
        break;
    }
  }

  closeMenu() {
    this.openDetails = false;
    this.menu.close();
  }

}
