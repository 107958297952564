import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './helpers/users.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'client-keys': environment.API_KEY
});

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public BASE_UR = environment.API_URL;
  public API_URL_DELIVERY = environment.API_URL_DELIVERY;

  constructor(
    private http: HttpClient,
    private user: UserService
  ) { }

  /**
  * Promo Double Point
  * @param data 
  */

  // Get Provice
  public getProvince(): Observable<any> {
    return this.http.get(this.API_URL_DELIVERY + 'location/prov', { headers });
  }
  // get City
  public getCity(prov): Observable<any> {
    return this.http.get(this.API_URL_DELIVERY + 'location/city?province=' + prov, { headers });
  }
  // get Kabupaten
  public getDistrict(prov, city): Observable<any> {
    return this.http.get(this.API_URL_DELIVERY + `location/district?province=${prov}&city=${city}`, { headers });
  }

  // Customer Login
  // public getCustomersLogin(username: any, pass: any): Observable<any> {
  //   return this.http.post(this.BASE_UR + `pomelo_web/sign`, { phone:username, pin:pass }, { headers });
  // }

  public getCustomersLogin(username: any, pass: any, company: any): Observable<any> {
    return this.http.post(this.BASE_UR + `auth/login`, { username, pass, company}, { headers });
  }

  // Customer Information
  // public getCustomerinformation(noClient: any): Observable<any> {
  //   const setHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'x-access-token': this.user.getToken()
  //   });
  //   return this.http.get(this.BASE_UR + `pomelo_web/customer/information/${noClient}`, { headers: setHeaders });
  // }

  public getCustomerinformation(noClient: any): Observable<any> {
    return this.http.get(this.BASE_UR + `member/informationclient?noClient=${noClient}`, { headers });
  }


  // Point History
  public getCustomerPointHistory(noClient: any, page: any): Observable<any> {
    return this.http.get(this.BASE_UR + `member/pointhistory?noClient=${noClient}&page=${page}`, { headers });
  }

  // update personal detail
  public savePersonaldetail(jsonData): Observable<any> {
    return this.http.post(this.BASE_UR + 'member/update', jsonData, { headers });
  }
  // chnage password 
  public saveChangePassword(jsonData): Observable<any> {
    return this.http.post(this.BASE_UR + 'auth/changepass', jsonData, { headers });
  }

}
