import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { AlertController, ModalController, LoadingController, ToastController, NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private goTo: NavController
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('pomelo_5ee4088f07b3f6e37337')));
        this.user = this.userSubject.asObservable();
        // console.log(this.userSubject.value);
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    logout() {
        // remove user from local storage and set current user to null
        //pomelo_5ee4088f07b3f6e37337
        localStorage.removeItem('pomelo_5ee4088f07b3f6e37337');
        // localStorage.clear();
        this.userSubject.next(null);
        // this.router.navigate(['/']);
        this.goTo.navigateRoot(['/']).then(() => {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        });
    }

    getno_client_new() {
        const users = this.userSubject.value;
        return users === null ? null : users.no_client;
    }

    getno_client() {
        const users = this.userSubject.value;
        return users === null ? null : users.no_client;
    }

    getno_client_old() {
        const users = this.userSubject.value;
        return users === null ? null : users.idClient;
    }

    getclient_name() {
        const users = this.userSubject.value;
        return users === null ? null : users.name;
    }

    getToken() {
        const users = this.userSubject.value;
        return users === null ? null : users.token;
    }

    getId() {
        const users = this.userSubject.value;
        return users === null ? null : users.id;
    }

    getPhone() {
        const users = this.userSubject.value;
        return users === null ? null : users.phone;
    }

    gethp() {
        const users = this.userSubject.value;
        return users === null ? null : users.hp;
    }

    getemail() {
        const users = this.userSubject.value;
        return users === null ? null : users.email;
    }

    getcardtype() {
        const users = this.userSubject.value;
        return users === null ? null : users.cardtype;
    }

    getktp() {
        const users = this.userSubject.value;
        return users === null ? null : users.ktp;
    }

    getaddress1() {
        const users = this.userSubject.value;
        return users === null ? null : users.address1;
    }

    getcity() {
        const users = this.userSubject.value;
        return users === null ? null : users.city;
    }

    getcountry() {
        const users = this.userSubject.value;
        return users === null ? null : users.country;
    }

    getprovince() {
        const users = this.userSubject.value;
        return users === null ? null : users.province;
    }

    getreligion() {
        const users = this.userSubject.value;
        return users === null ? null : users.religion;
    }

    getpekerjaan() {
        const users = this.userSubject.value;
        return users === null ? null : users.pekerjaan;
    }

    getzipcode() {
        const users = this.userSubject.value;
        return users === null ? null : users.zipcode;
    }

    getgender() {
        const users = this.userSubject.value;
        return users === null ? null : users.gender;
    }

    getdt_birth() {
        const users = this.userSubject.value;
        return users === null ? null : users.dt_birth;
    }


    getDD() {
        const users = this.userSubject.value;
        return users === null ? null : users.dd;
    }

    getMM() {
        const users = this.userSubject.value;
        return users === null ? null : users.mm;
    }

    getYY() {
        const users = this.userSubject.value;
        return users === null ? null : users.yy;
    }

    getbranch() {
        const users = this.userSubject.value;
        return users === null ? null : users.branch;
    }

    getstatus_vip() {
        const users = this.userSubject.value;
        return users === null ? null : users.status_vip;
    }

    getno_inv() {
        const users = this.userSubject.value;
        return users === null ? null : users.no_inv;
    }

    getdt_inv() {
        const users = this.userSubject.value;
        return users === null ? null : users.dt_inv;
    }
    getno_branch() {
        const users = this.userSubject.value;
        return users === null ? null : users.no_branch;
    }


}
